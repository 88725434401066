import {
    USERS_GET,
    USERS_SUCCESS,
    USERS_FAILURE,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FALIURE,
    GET_SHARED_RECORDING,
    GET_SHARED_RECORDING_SUCCESS,
    GET_SHARED_RECORDING_FALIURE,
    GET_CATEGORIES_LIST,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_FALIURE,
    GET_QUESTIONS_LIST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FALIURE,
    UPLOAD_CATEGORY_JSON,
    UPLOAD_CATEGORY_JSON_SUCCESS,
    UPLOAD_CATEGORY_JSON_FALIURE,
    GET_USERS_LIST,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FALIURE,
    CHANGE_USER_STATUS,
    CHANGE_USER_STATUS_SUCCESS,
    CHANGE_USER_STATUS_FALIURE,
    GET_STATIC_CONTENT,
    GET_STATIC_CONTENT_SUCCESS,
    GET_STATIC_CONTENT_FALIURE,
    UPDATE_STATIC_CONTENT,
    UPDATE_STATIC_CONTENT_SUCCESS,
    UPDATE_STATIC_CONTENT_FALIURE,
    UPLOAD_QUESTION_JSON,
    UPLOAD_QUESTION_JSON_SUCCESS,
    UPLOAD_QUESTION_JSON_FALIURE,
    UPDATE_CATEGORY_IMAGE,
    UPDATE_CATEGORY_IMAGE_SUCCESS,
    UPDATE_CATEGORY_IMAGE_FALIURE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FALIURE,
    GET_ALL_SUBSCRIPTIONS,
    GET_ALL_SUBSCRIPTIONS_SUCCESS,
    GET_ALL_SUBSCRIPTIONS_FALIURE,
    CHANGE_SUBSCRIPTION_STATUS,
    CHANGE_SUBSCRIPTION_STATUS_SUCCESS,
    CHANGE_SUBSCRIPTION_STATUS_FALIURE,
    GET_USER_REPORT,
    GET_USER_REPORT_SUCCESS,
    GET_USER_REPORT_FALIURE,
    GET_RECORDING_REPORT,
    GET_RECORDING_REPORT_SUCCESS,
    GET_RECORDING_REPORT_FALIURE,
    SEND_RESET_PASSWORD_EMAIL,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FALIURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FALIURE,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FALIURE,
    DELETE_QUESTION,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FALIURE,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FALIURE,
    GET_SUBSCRIBED_USER,
    GET_SUBSCRIBED_USER_SUCCESS,
    GET_SUBSCRIBED_USER_FALIURE
} from '../constants/constants';


export const getUsers = () => ({
  type: USERS_GET
})

export const getUsersSuccess = users => ({
  type: USERS_SUCCESS,
  users
})

export const getUsersFailure = error => ({
  type: USERS_FAILURE,
  error
})

export const login = data =>({
	type:LOGIN,
	payload:data
})

export const login_success = (token) =>({
	type:LOGIN_SUCCESS,
	payload:token
})
export const login_faliure = (error) =>({
	type:LOGIN_FALIURE,
	payload:error
})

export const getSharedRecording = (data) => ({
  type: GET_SHARED_RECORDING,
  payload:data
})

export const getSharedRecordingSuccess = sharedRecordingDetails => ({
  type: GET_SHARED_RECORDING_SUCCESS,
  sharedRecordingDetails
})

export const getSharedRecordingFailure = error => ({
  type: GET_SHARED_RECORDING_FALIURE,
  error
})

export const getCategoriesList = (data) => ({
  type: GET_CATEGORIES_LIST,
  payload: data
})

export const getCategoriesListSuccess = categoriesList => ({
  type: GET_CATEGORIES_LIST_SUCCESS,
  categoriesList
})

export const getCategoriesListFailure = error => ({
  type: GET_CATEGORIES_LIST_FALIURE,
  error
})

export const getQuestionsList = (data) => ({
  type: GET_QUESTIONS_LIST,
  payload: data
})

export const getQuestionsListSuccess = questionsList => ({
  type: GET_QUESTIONS_LIST_SUCCESS,
  questionsList
})

export const getQuestionsListFailure = error => ({
  type: GET_QUESTIONS_LIST_FALIURE,
  error
})

export const uploadCategoryJson = (data) => ({
  type: UPLOAD_CATEGORY_JSON,
  payload:data
})

export const uploadCategoryJsonSuccess = categoriesUploaded => ({
  type: UPLOAD_CATEGORY_JSON_SUCCESS,
  categoriesUploaded
})

export const uploadCategoryJsonFailure = error => ({
  type: UPLOAD_CATEGORY_JSON_FALIURE,
  error
})

export const uploadQuestionJson = (data) => ({
  type: UPLOAD_QUESTION_JSON,
  payload:data
})

export const uploadQuestionJsonSuccess = questionsUploaded => ({
  type: UPLOAD_QUESTION_JSON_SUCCESS,
  questionsUploaded
})

export const uploadQuestionJsonFailure = error => ({
  type: UPLOAD_QUESTION_JSON_FALIURE,
  error
})

export const getUsersList = (data) => ({
  type: GET_USERS_LIST,
  payload: data
})

export const getUsersListSuccess = usersList => ({
  type: GET_USERS_LIST_SUCCESS,
  usersList
})

export const getUsersListFailure = error => ({
  type: GET_USERS_LIST_FALIURE,
  error
})

export const changeUserStatus = (data) => ({
  type: CHANGE_USER_STATUS,
  payload: data
})

export const changeUserStatusSuccess = data => ({
  type: CHANGE_USER_STATUS_SUCCESS,
  data
})

export const changeUserStatusFaliure = error => ({
  type: CHANGE_USER_STATUS_FALIURE,
  error
})

export const getStaticContent = (data) => ({
  type: GET_STATIC_CONTENT,
  payload: data
})

export const getStaticContentSuccess = data => ({
  type: GET_STATIC_CONTENT_SUCCESS,
  data
})

export const getStaticContentFaliure = error => ({
  type: GET_STATIC_CONTENT_FALIURE,
  error
})

export const updateStaticContent = (data) => ({
  type: UPDATE_STATIC_CONTENT,
  payload: data
})

export const updateStaticContentSuccess = data => ({
  type: UPDATE_STATIC_CONTENT_SUCCESS,
  data
})

export const updateStaticContentFailure = error => ({
  type: UPDATE_STATIC_CONTENT_FALIURE,
  error
})

export const updateCategoryImage = (data) => ({
  type: UPDATE_CATEGORY_IMAGE,
  payload: data
})

export const updateCategoryImageSuccess = data => ({
  type: UPDATE_CATEGORY_IMAGE_SUCCESS,
  data
})

export const updateCategoryImageFailure = error => ({
  type: UPDATE_CATEGORY_IMAGE_FALIURE,
  error
})

export const changePassword = (data) => ({
  type: CHANGE_PASSWORD,
  payload: data
})

export const changePasswordSuccess = data => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data
})

export const changePasswordFailure = error => ({
  type: CHANGE_PASSWORD_FALIURE,
  error
})

export const getAllSubscriptions = (data) => ({
  type: GET_ALL_SUBSCRIPTIONS,
  payload: data
})

export const getAllSubscriptionsSuccess = data => ({
  type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
  data
})

export const getAllSubscriptionsFaliure = error => ({
  type: GET_ALL_SUBSCRIPTIONS_FALIURE,
  error
})

export const changeSubscriptionStatus = (data) => ({
  type: CHANGE_SUBSCRIPTION_STATUS,
  payload: data
})

export const changeSubscriptionStatusSuccess = data => ({
  type: CHANGE_SUBSCRIPTION_STATUS_SUCCESS,
  data
})

export const changeSubscriptionStatusFaliure = error => ({
  type: CHANGE_SUBSCRIPTION_STATUS_FALIURE,
  error
})

export const getUserReport = (data) => ({
  type: GET_USER_REPORT,
  payload: data
})

export const getUserReportSuccess = data => ({
  type: GET_USER_REPORT_SUCCESS,
  data
})

export const getUserReportFaliure = error => ({
  type: GET_USER_REPORT_FALIURE,
  error
})

export const getRecordingReport = (data) => ({
  type: GET_RECORDING_REPORT,
  payload: data
})

export const getRecordingReportSuccess = data => ({
  type: GET_RECORDING_REPORT_SUCCESS,
  data
})

export const getRecordingReportFaliure = error => ({
  type: GET_RECORDING_REPORT_FALIURE,
  error
})

export const sendResetPasswordEmail = (data) => ({
  type: SEND_RESET_PASSWORD_EMAIL,
  payload: data
})

export const sendResetPasswordEmailSuccess = data => ({
  type: SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  data
})

export const sendResetPasswordEmailFaliure = error => ({
  type: SEND_RESET_PASSWORD_EMAIL_FALIURE,
  error
})

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  payload: data
})

export const resetPasswordSuccess = data => ({
  type: RESET_PASSWORD_SUCCESS,
  data
})

export const resetPasswordFaliure = error => ({
  type: RESET_PASSWORD_FALIURE,
  error
})

export const deleteCategory = (data) => ({
  type: DELETE_CATEGORY,
  payload: data
})

export const deleteCategorySuccess = data => ({
  type: DELETE_CATEGORY_SUCCESS,
  data
})

export const deleteCategoryFaliure = error => ({
  type: DELETE_CATEGORY_FALIURE,
  error
})

export const deleteQuestion = (data) => ({
  type: DELETE_QUESTION,
  payload: data
})

export const deleteQuestionSuccess = data => ({
  type: DELETE_QUESTION_SUCCESS,
  data
})

export const deleteQuestionFaliure = error => ({
  type: DELETE_QUESTION_FALIURE,
  error
})

export const verifyEmail = (data) => ({
  type: VERIFY_EMAIL,
  payload: data
})

export const verifyEmailSuccess = data => ({
  type: VERIFY_EMAIL_SUCCESS,
  data
})

export const verifyEmailFaliure = error => ({
  type: VERIFY_EMAIL_FALIURE,
  error
})

export const getSubscribedUser = (data) => ({
  type: GET_SUBSCRIBED_USER,
  payload: data
})

export const getSubscribedUserSuccess = data => ({
  type: GET_SUBSCRIBED_USER_SUCCESS,
  data
})

export const getSubscribedUserFaliure = error => ({
  type: GET_SUBSCRIBED_USER_FALIURE,
  error
})