import { all, call, put, takeEvery, spawn, takeLatest } from 'redux-saga/effects'
import axios from 'axios';
//import { push } from 'react-router-redux';

import {
    getUsers,
    getUsersSuccess,
    getUsersFailure,
    login,
    login_success,
    login_faliure,
    getSharedRecording,
    getSharedRecordingSuccess,
    getSharedRecordingFailure,
    getCategoriesList,
    getCategoriesListSuccess,
    getCategoriesListFailure,
    getQuestionsList,
    getQuestionsListSuccess,
    getQuestionsListFailure,
    uploadCategoryJson,
    uploadCategoryJsonSuccess,
    uploadCategoryJsonFailure,
    getUserssList,
    getUsersListSuccess,
    getUsersListFailure,
    changeUserStatus,
    changeUserStatusSuccess,
    changeUserStatusFaliure,
    getStaticContent,
    getStaticContentSuccess,
    getStaticContentFaliure,
    updateStaticContent,
    updateStaticContentSuccess,
    updateStaticContentFailure,
    uploadQuestionJson,
    uploadQuestionJsonSuccess,
    uploadQuestionJsonFailure,
    updateCategoryImage,
    updateCategoryImageSuccess,
    updateCategoryImageFailure,
    changePassword,
    changePasswordSuccess,
    changePasswordFailure,
    getAllSubscriptions,
    getAllSubscriptionsSuccess,
    getAllSubscriptionsFaliure,
    changeSubscriptionStatus,
    changeSubscriptionStatusSuccess,
    changeSubscriptionStatusFaliure,
    getUserReport,
    getUserReportSuccess,
    getUserReportFaliure,
    getRecordingReport,
    getRecordingReportSuccess,
    getRecordingReportFaliure,
    sendResetPasswordEmail,
    sendResetPasswordEmailSuccess,
    sendResetPasswordEmailFaliure,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFaliure,
    deleteCategory,
    deleteCategorySuccess,
    deleteCategoryFaliure,
    deleteQuestion,
    deleteQuestionSuccess,
    deleteQuestionFaliure,
    verifyEmail,
    verifyEmailSuccess,
    verifyEmailFaliure,
    getSubscribedUser,
    getSubscribedUserSuccess,
    getSubscribedUserFaliure
} from '../actions/actions';
import {
    USERS_GET,
    USERS_SUCCESS,
    USERS_FAILURE,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FALIURE,
    GET_SHARED_RECORDING,
    GET_SHARED_RECORDING_SUCCESS,
    GET_SHARED_RECORDING_FALIURE,
    GET_CATEGORIES_LIST,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_FALIURE,
    GET_QUESTIONS_LIST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FALIURE,
    UPLOAD_CATEGORY_JSON,
    UPLOAD_CATEGORY_JSON_SUCCESS,
    UPLOAD_CATEGORY_JSON_FALIURE,
    GET_USERS_LIST,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FALIURE,
    CHANGE_USER_STATUS,
    CHANGE_USER_STATUS_SUCCESS,
    CHANGE_USER_STATUS_FALIURE,
    GET_STATIC_CONTENT,
    GET_STATIC_CONTENT_SUCCESS,
    GET_STATIC_CONTENT_FALIURE,
    UPDATE_STATIC_CONTENT,
    UPDATE_STATIC_CONTENT_SUCCESS,
    UPDATE_STATIC_CONTENT_FALIURE,
    UPLOAD_QUESTION_JSON,
    UPLOAD_QUESTION_JSON_SUCCESS,
    UPLOAD_QUESTION_JSON_FALIURE,
    UPDATE_CATEGORY_IMAGE,
    UPDATE_CATEGORY_IMAGE_SUCCESS,
    UPDATE_CATEGORY_IMAGE_FALIURE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FALIURE,
    GET_ALL_SUBSCRIPTIONS,
    GET_ALL_SUBSCRIPTIONS_SUCCESS,
    GET_ALL_SUBSCRIPTIONS_FALIURE,
    CHANGE_SUBSCRIPTION_STATUS,
    CHANGE_SUBSCRIPTION_STATUS_SUCCESS,
    CHANGE_SUBSCRIPTION_STATUS_FALIURE,
    GET_USER_REPORT,
    GET_USER_REPORT_SUCCESS,
    GET_USER_REPORT_FALIURE,
    GET_RECORDING_REPORT,
    GET_RECORDING_REPORT_SUCCESS,
    GET_RECORDING_REPORT_FALIURE,
    SEND_RESET_PASSWORD_EMAIL,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FALIURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FALIURE,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FALIURE,
    DELETE_QUESTION,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FALIURE,
    GET_SUBSCRIBED_USER,
    GET_SUBSCRIBED_USER_SUCCESS,
    GET_SUBSCRIBED_USER_FALIURE
} from '../constants/constants';

//import * as routes from '../constants/routes'
const origin = window.location.origin
// const origin = 'https://stage.mindbank.ai'
const token = window.localStorage.getItem('token')


const sessionTimeOut = () =>{
    if (token) {
        localStorage.removeItem('isAdminAuthenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.replace('/admin/login')   
    }
}


function* loginFunc(action) {
    try {
        const response = yield axios.post(origin+'/user/api/v1/user/login', action.payload);
        if (response.status === 200) {
            let userInfo = response.data
            if (userInfo.type == 'admin') {
                window.localStorage.setItem("user", JSON.stringify(userInfo));
                window.localStorage.setItem("token", userInfo.token);
                window.localStorage.setItem("isAdminAuthenticated", true)
                yield put(login_success(userInfo.token));
                yield put(login_faliure(null))   
            }else{
                throw {"message":"User is not admin"}
            }
        }
    } catch (err) {
        console.log(err)
        if (err.response.status === 404) {
                yield put(login_faliure({"message":"Something went wrong, please contact contact@mindbank.ai"}))
            }else{      
                yield put(login_faliure(err.response?err.response.data:err));
            }
    }
}

function* getSharedRecordingFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: {
            plateform: action.payload.plateform,
            id: action.payload.id
          },
        }
        const response = yield axios.get(origin+'/recording/api/v1/share_recording', config);
         if (response.status === 200) {
             yield put(getSharedRecordingSuccess(response.data.data));
        }
    } catch (err) {
        console.log(err);
        yield put(login_faliure(err));
    }
}

function* getCategoriesListFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params:action.payload
        }
        const response = yield axios.get(origin+'/content/api/v1/category/get-all-categories-for-admin', config);
         if (response.status === 200) {
             yield put(getCategoriesListSuccess(response.data.data));
        }
    } catch (err) {
        console.log(err);
        yield put(getCategoriesListFailure(err));
    }
}

function* getQuestionsListFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload
        }
        const response = yield axios.get(origin+'/content/api/v1/question/get-all-questions-for-admin', config);
         if (response.status === 200) {
             yield put(getQuestionsListSuccess(response.data.data));
        }
    } catch (err) {
        console.log(err);
        yield put(getQuestionsListFailure(err));
    }
}

function* getUsersListFunc(action) {
    if (!token) {
        window.location.reload()
    }
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload
        }
        const response = yield axios.get(origin+'/user/api/v1/user/admin/get-users', config);
         if (response.status === 200) {
             yield put(getUsersListSuccess(response.data.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
            yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
            yield put(sessionTimeOut())
        }
        console.log(err);
        yield put(getUsersListFailure(err));
    }
}

function* uploadCategoryJsonFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
            "Content-Type": "multipart/form-data"
           },
        }
        const response = yield axios.post(origin+'/content/api/v1/category/upload-category', action.payload, config);
        if (response.status === 200) {
            yield put(uploadCategoryJsonSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(uploadCategoryJsonFailure(err));
        yield put(uploadCategoryJsonSuccess({"success":true,"message":"notUploaded"}));
    }
}

function* uploadQuestionJsonFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
            "Content-Type": "multipart/form-data"
           },
        }
        const response = yield axios.post(origin+'/content/api/v1/question/upload-questions', action.payload, config);
        if (response.status === 200) {
            yield put(uploadQuestionJsonSuccess(response.data));

        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(uploadQuestionJsonFailure(err));
        yield put(uploadQuestionJsonSuccess({"success":true,"message":"notUploaded"}));
    }
}

function* changeUserStatusFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
           },
        }
        const response = yield axios.put(origin+'/user/api/v1/user/admin/change-user-status', action.payload, config);
        if (response.status === 200) {
            yield put(changeUserStatusSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(changeUserStatusFaliure(err));
    }
}

function* getStaticContentFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
           },
           params:{

            type:action.payload
           }
        }
        const response = yield axios.get(origin+'/content/api/v1/content/get-static-content', config);
        if (response.status === 200) {
            console.log(response.data)
            yield put(getStaticContentSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(getStaticContentFaliure(err));
    }
}

function* updateStaticContentFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
           },
        }
        const response = yield axios.put(origin+'/content/api/v1/content/update-static-content', action.payload, config);
        if (response.status === 201) {
            yield put(updateStaticContentSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(updateStaticContentFailure(err));
    }
}

function* updateCategoryImageFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
           },
        }
        const response = yield axios.put(origin+'/content/api/v1/category/update-category', action.payload, config);
        if (response.status === 200) {
            yield put(updateCategoryImageSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(updateCategoryImageSuccess(err.response?err.response.data:err));
    }
}

function* changePasswordFunc(action) {
    try { 
        let config = {
          headers: {
            'Authorization':token,
           },
        }
        const response = yield axios.put(origin+'/user/api/v1/user/change-password', action.payload, config);
        if (response.status === 200) {
            yield put(changePasswordSuccess(response.data));
        } 
    }
    catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        if (err.response.status) {
            yield put(changePasswordSuccess(err.response.data));
        }
        yield put(updateCategoryImageFailure(err));
    }
}

function* getAllSubscriptionFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token}
        }
        const response = yield axios.get(origin+'/user/api/v1/subscription/get-all-subscriptions-for-admin', config);
         if (response.status === 200) {
             yield put(getAllSubscriptionsSuccess(response.data.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(getAllSubscriptionsFaliure(err));
    }
}

function* changeSubscriptionStatusFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token}
        }
        const response = yield axios.put(origin+'/user/api/v1/subscription/change-subscription-status', action.payload, config);
         if (response.status === 200) {
             yield put(changeSubscriptionStatusSuccess(response.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(changeSubscriptionStatusFaliure(err));
    }
}

function* getUserReportFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.get(origin+'/user/api/v1/user/admin/get-user-report', config);
         if (response.status === 200) {
             yield put(getUserReportSuccess(response.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(getUserReportFaliure(err));
    }
}

function* getRecordingReportFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.get(origin+'/recording/api/v1/recording/admin/get-recording-report', config);
         if (response.status === 200) {
             yield put(getRecordingReportSuccess(response.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(getRecordingReportFaliure(err));
    }
}

function* sendResetPasswordEmailFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.post(origin+'/user/api/v1/user/send-reset-password-email', action.payload, config);
         if (response.status === 200) {
             yield put(sendResetPasswordEmailSuccess(response.data));
        }
    } catch (err) {
        console.log(err);
        yield put(sendResetPasswordEmailSuccess({}));
    }
}

function* resetPasswordFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.post(origin+'/user/api/v1/user/reset-password', action.payload, config);
        console.log(response.data)
         if (response.status === 200) {
             yield put(resetPasswordSuccess(response.data));
        }
    } catch (err) {
        console.log(err);
        yield put(resetPasswordSuccess(err.response.data));
    }
}

function* deleteCategoryFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.delete(origin+'/content/api/v1/category/delete-category', config);
        console.log(response.data)
         if (response.status === 200) {
            console.log("category delete")
             yield put(deleteCategorySuccess(response.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(deleteCategoryFaliure(err));
    }
}

function* deleteQuestionFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload,
        }
        const response = yield axios.delete(origin+'/content/api/v1/question/delete-question', config);
        console.log(response.data)
         if (response.status === 200) {
            console.log("question delete")
             yield put(deleteQuestionSuccess(response.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(deleteQuestionFaliure(err));
    }
}

function* verifyEmailFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
        }
        const response = yield axios.put(origin+'/user/api/v1/user/verify-user',action.payload, config);
        console.log(response.data)
         if (response.status === 200) {
            console.log("verifyEmail")
             yield put(verifyEmailSuccess(response.data));
        }
    } catch (err) {
        console.log(err);
        yield put(verifyEmailFaliure(err));
    }
}

function* getSubscribedUserFunc(action) {
    try {
        let config = {
          headers: {'Authorization':token },
          params: action.payload
        }
        const response = yield axios.get(origin+'/user/api/v1/user/admin/get-subscribed-user', config);
         if (response.status === 200) {
            console.log(response.data)
             yield put(getSubscribedUserSuccess(response.data.data));
        }
    } catch (err) {
        if (err.response.status === 403) {
                yield put(login_faliure({"message":"Session Timed out Please Sign in again"}))
                yield put(sessionTimeOut())
            }
        console.log(err);
        yield put(getSubscribedUserSuccess(err));
    }
}


function* loginSaga () {
  yield takeLatest(LOGIN, loginFunc);
}

function* sharedRecordingSaga () {
  yield takeLatest(GET_SHARED_RECORDING, getSharedRecordingFunc);
}

function* getCategoriesListSaga () {
    yield takeLatest(GET_CATEGORIES_LIST, getCategoriesListFunc)
}

function* getQuestionsListSaga () {
    yield takeLatest(GET_QUESTIONS_LIST, getQuestionsListFunc)
}

function* uploadCategoryJsonSaga () {
    yield takeLatest(UPLOAD_CATEGORY_JSON, uploadCategoryJsonFunc)
}

function* getUsersListSaga () {
    yield takeLatest(GET_USERS_LIST, getUsersListFunc)
}

function* changeUserStatusSaga () {
    yield takeLatest(CHANGE_USER_STATUS, changeUserStatusFunc)
}

function* getStaticContentSaga () {
    yield takeLatest(GET_STATIC_CONTENT, getStaticContentFunc)
}

function* updateStaticContentSaga () {
    yield takeLatest(UPDATE_STATIC_CONTENT, updateStaticContentFunc)
}
function* uploadQuestionJsonSaga () {
    yield takeLatest(UPLOAD_QUESTION_JSON, uploadQuestionJsonFunc)
}
function* updateCategoryImageSaga () {
    yield takeLatest(UPDATE_CATEGORY_IMAGE, updateCategoryImageFunc)
}

function* changePasswordSaga () {
    yield takeLatest(CHANGE_PASSWORD, changePasswordFunc)
}

function* getAllSubscriptionSaga () {
    yield takeLatest(GET_ALL_SUBSCRIPTIONS, getAllSubscriptionFunc)
}

function* changeSubscriptionStatusSaga () {
    yield takeLatest(CHANGE_SUBSCRIPTION_STATUS, changeSubscriptionStatusFunc)
}

function* getUserReportSaga () {
    yield takeLatest(GET_USER_REPORT, getUserReportFunc)
}

function* getRecordingReportSaga () {
    yield takeLatest(GET_RECORDING_REPORT, getRecordingReportFunc)
}

function* sendResetPasswordEmailSaga () {
    yield takeLatest(SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmailFunc)
}

function* resetPasswordSaga () {
    yield takeLatest(RESET_PASSWORD, resetPasswordFunc)
}

function* deleteCategorySaga () {
    yield takeLatest(DELETE_CATEGORY, deleteCategoryFunc)
}

function* deleteQuestionSaga () {
    yield takeLatest(DELETE_QUESTION, deleteQuestionFunc)
}

function* verifyEmailSaga () {
    yield takeLatest(VERIFY_EMAIL, verifyEmailFunc)
}

function* getSubscribedUserSaga () {
    yield takeLatest(GET_SUBSCRIBED_USER, getSubscribedUserFunc)
}

export default function* rootSaga() {
  yield all([
    loginSaga(),
    sharedRecordingSaga(),
    getCategoriesListSaga(),
    getQuestionsListSaga(),
    uploadCategoryJsonSaga(),
    getUsersListSaga(),
    changeUserStatusSaga(),
    getStaticContentSaga(),
    updateStaticContentSaga(),
    uploadQuestionJsonSaga(),
    updateCategoryImageSaga(),
    changePasswordSaga(),
    getAllSubscriptionSaga(),
    changeSubscriptionStatusSaga(),
    getUserReportSaga(),
    getRecordingReportSaga(),
    sendResetPasswordEmailSaga(),
    resetPasswordSaga(),
    deleteCategorySaga(),
    deleteQuestionSaga(),
    verifyEmailSaga(),
    getSubscribedUserSaga()
  ])
}



