import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { useHistory } from "react-router-dom";

import { getUsers, login } from '../../../actions/actions';
import { selectUsersData, selectUserToken, selectLoginFailure } from '../../../selectors/selectors';

// import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css'


const EmailSent = ({
    users,
    getUsers,
    token,
    login,
    error,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(true);
    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory();

    const authed = localStorage.getItem('isAdminAuthenticated')
    const authedtoken = localStorage.getItem('token')

    const callLoginFunc = ((e) => {
        e.preventDefault()
        let data = { "email": email, "password": password }
        login(data)
        setShowErrMsg(true)
    })

    const setEmailFunc = (e) => {
        setEmail(e.target.value)
    }

    const setPasswordFunc = (e) => {
        setPassword(e.target.value)
    }

    useEffect(() => {
        if (authed) {
            history.push('/admin/dashboard/user-management')
        }
    })
    return (
        <div>
            <div>
                <title>MindBank | Signin</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" />
            </div>

            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 p-0">
                            <div className="login-bg">
                                {/*<!-- <img alt="Logo" className="img-responsive signin-logo" src="assets/images/logo.png" width="198" />
                        <p><span>PROMAKERS</span> push their own limits in the game and in life. Get Inspired to elevate
                            your training with the best programs & products in sports and fitness – Become a
                            <span>POWERHANDZ PROMAKER</span>.</p> -->*/}
                            </div>
                        </div>
                    <div className="col-6 p-0">
                        {(error && showErrMsg) ?
                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                <span className="icon icon-info"></span> {error.message}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => { setShowErrMsg(false) }}></button>
                            </div> : null

                        }
                    <div class="signin-container">

                    <form class="login-container" action="dashboard.html">
 
                        <img alt="Logo" class="img-responsive signin-logo signin-logo-space" src="/images/logo.png" width="198" />
                        <img alt="Logo" class="img-responsive forgotemailsent-logo" src="/images/confirmation-icon.svg" />

                        <h1 id="head-bootomsapce">Email sent successfully!</h1>
                        <p class="sub-head-center">to get back into your account, follow the instructions <br /> we've sent to your provided email address.</p>
                        

                        
                        <div class="form-group form-group-space">
                        </div> 
                        <div class="form-action">
                            <button data-testid="back-to-sign-in-button" class="btn primary primary-uppercase btn-block" type="submit" onClick ={()=>{history.push('/admin/login')}}>Back to SIGN IN</button>
                        </div>

                    </form>

                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

const mapStateToProps = createStructuredSelector({
    // users:selectUsersData(),
    token: selectUserToken(),
    error: selectLoginFailure()
});


const mapDispatchToProps = dispatch => ({
    // getUsers: () => {
    //   return dispatch(getUsers())
    // },
    login: (x) => {
        return dispatch(login(x))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailSent)

