import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';


export const Popup = (props) => {

  // const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { setShowPopup, popUpBody, popUpHeading, popUpType, setConfirmed } = props;
  const setConfirmedFunc =()=>{
    setConfirmed(true)
    setShowPopup(false)
  }

  return (
    <>
      <Modal show={true} data-testid="modal" >
        <Modal.Header style={{"background":"none","marginLeft":"22%", "border":"none", "width":"auto"}}>
          <Modal.Title style={{"fontWeight":"600", "fontSize":"25px","marginTop":"30px"}}>{popUpHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{"textAlign":"center"}}>{popUpBody}</Modal.Body>
        <Modal.Footer>
        {(popUpType !== 'alert')?
          <Button variant="secondary" data-testid="modal-close" onClick ={()=>{setShowPopup(false)}} >
            Cancel
          </Button>:null
        }
          <Button variant="primary" data-testid="modal-ok" onClick={(popUpType == 'alert')?()=>{setShowPopup(false)}:()=>{setConfirmedFunc(true)}}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup;