import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'

import * as routes from '../../Routes/routes'

import PageLoading from '../common/PageLoading'

import ProtectRoute from './protectRoute'

import ForgotPassword from '../Pages/Login/forgotPassword';
import ResetPassword from '../Pages/Login/resetPassword';
import VerifyEmail from '../Pages/Login/verifyEmail';


import './index.css'

const HomePage = React.lazy(() => import('../Pages/Home'))
const ShareLandingPage = React.lazy(() => import('../Pages/ShareLandingPage'))
const Login = React.lazy(() => import('../Pages/Login'))


const App = () => {

  return (
    <Suspense fallback={<PageLoading />}>
      <div className="app">
        <Route exact path={routes.Home} component={() => <HomePage />} />
        <Route exact path={routes.ShareLandingPage} component={() => <ShareLandingPage />} />
        <Route exact path={routes.Login} component={() => <Login />} />
        <Route exact path={routes.CategoryManagement} component={() => <ProtectRoute><HomePage /></ProtectRoute>} />
        <Route exact path={routes.ForgotPassword} component={() => <ForgotPassword />} />
        <Route exact path={routes.VerifyEmail} component={() => <VerifyEmail />} />
        <Route exact path={routes.ResetPassword} component={() => <ResetPassword />} />
      </div>
    </Suspense>
  )
}

export default App
