import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams, useLocation } from "react-router-dom";

import { getUsers, login, resetPassword, resetPasswordSuccess } from '../../../actions/actions';
import { selectUsersData, selectUserToken, selectLoginFailure, selectResetPasswordData } from '../../../selectors/selectors';

import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css'
import Popup from '../../common/Popup';


const ResetPassword = ({
    users,
    getUsers,
    token,
    login,
    error,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordData,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [popUpType, setPopUpType] = useState('alert')
    const [popUpBody, setPopUpBody] = useState('')
    const [popUpHeading, setPopUpHeading] = useState('')
    const [confirmed, setConfirmed] = useState(false)


    const history = useHistory();

    var paramVal = useParams();

    const { id } = paramVal ? paramVal : { id: null }



    const authed = localStorage.getItem('isAdminAuthenticated')
    const authedtoken = localStorage.getItem('token')

    const callResetPasswordFunc = ((e)=>{
       e.preventDefault()
       if(newPassword.length<=7){ 
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Password should have minimum 8 characters')
          setPopUpType('alert')
       }else if(newPassword.includes(' ')){ 
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Password should not have space in it')
          setPopUpType('alert')
       }else if ((newPassword && confirmNewPassword) && (newPassword == confirmNewPassword) && newPassword.length>7){
            let data = {id:id,"newPassword":newPassword}
            resetPassword(data)
       }else if(!newPassword){
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Please enter new password')
          setPopUpType('alert')
       }else if(!confirmNewPassword){
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Please enter confirm new password')
          setPopUpType('alert')
       }else if(!(newPassword == confirmNewPassword)){
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('New password and confirm new password are not same')
          setPopUpType('alert')
       }
    })

    const setEmailFunc = (e) => {
        setEmail(e.target.value)
    }

    const setPasswordFunc = (e) => {
        setPassword(e.target.value)
    }

    const setNewPasswordFunc = (e) => {
        setNewPassword(e.target.value)
    }

    const setConfirmNewPasswordFunc = (e) => {
        setConfirmNewPassword(e.target.value)
    }

    const showMessages = () =>{
        if(resetPasswordData && (resetPasswordData.message == 'Updated!')){
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Password updated!')
          setPopUpType('alert')
        }else if(resetPasswordData){
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody(resetPasswordData.message)
          setPopUpType('alert')
        }else{
          setShowPopup(true)
          setPopUpHeading('Reset Password')
          setPopUpBody('Something went wrong')
          setPopUpType('alert')
        }
        resetPasswordSuccess()
    }


    useEffect(() => {
        if (authed) {
            history.push('/admin/dashboard/user-management')
        }
        resetPasswordSuccess()
    })

    useEffect(() => {
        if (resetPasswordData && resetPasswordData.message) {
        showMessages()
        }
    },[resetPasswordData])
    return (
        <div>
            <div>
                <title>MindBank | Reset Password</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"></link>
            </div>

            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 p-0 verified-email-login-bg">
                            <div className="login-bg">
                                {/*<!-- <img alt="Logo" className="img-responsive signin-logo" src="assets/images/logo.png" width="198" />
                        <p><span>PROMAKERS</span> push their own limits in the game and in life. Get Inspired to elevate
                            your training with the best programs & products in sports and fitness – Become a
                            <span>POWERHANDZ PROMAKER</span>.</p> -->*/}
                            </div>
                        </div>
                        <div className="col-6 p-0 reset-col-6">
                            {(showPopup)?
                              <Popup
                                setShowPopup={setShowPopup}
                                popUpBody={popUpBody}
                                popUpHeading={popUpHeading}
                                popUpType={popUpType}
                                setConfirmed={setConfirmed}
                              />:null
                            }
                            <div class="signin-container reset-password-container">

                                <form class="login-container reset-pw-login-container reset-container" action="emil-confirm.html">

                                    <img alt="Logo" class="img-responsive signin-logo signin-logo-space"
                                        src="/images/logo.png" width="198" />

                                    <h1 id="head-topmsapce" class="verified-text">Reset Password</h1>
                                    <p class="sub-head-center success-text">Enter new password and confirm it.</p>

                                    <span className="input-lable-head">New Password</span>
                                    <div className="input-data width100">
                                        <input data-testid="newpassword-input" type={(showNewPassword) ? "text" : "password"} className="form-control width100" id="inputGroupFile02" placeholder="New Password" onChange={setNewPasswordFunc} />
                                        <span className="p-viewer pointer">
                                            <img src={(showNewPassword) ? "/images/eye-line.svg" : "/images/eye-off-line.svg"} data-testid="newPassword-eye" alt="" className="fa fa-eye" aria-hidden="true" onClick={() => setShowNewPassword((!showNewPassword) ? true : false)} />
                                        </span>

                                    </div>

                                    <span className="input-lable-head">Confirm New Password</span>
                                    <div className="input-data">
                                        <input data-testid='confirmNewpassword-input' type={(showConfirmNewPassword) ? "text" : "password"} className="form-control width100" id="inputGroupFile02" placeholder="Confirm New Password" onChange={setConfirmNewPasswordFunc} />
                                        <span className="p-viewer pointer">
                                            <img data-testid="confirmNewPassword-eye" src={(showConfirmNewPassword) ? "/images/eye-line.svg" : "/images/eye-off-line.svg"} alt="" className="fa fa-eye" aria-hidden="true" onClick={() => setShowConfirmNewPassword((!showConfirmNewPassword) ? true : false)} />
                                        </span>

                                    </div>
                                    <div class="form-action">
                                        <button data-testid="submit-button" class="btn primary primary-uppercase btn-block" onClick={callResetPasswordFunc} type="submit">save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    // users:selectUsersData(),
    token: selectUserToken(),
    error: selectLoginFailure(),
    resetPasswordData: selectResetPasswordData()
});


const mapDispatchToProps = dispatch => ({
    // getUsers: () => {
    //   return dispatch(getUsers())
    // },
    login: (x) => {
        return dispatch(login(x))
    },
    resetPassword: (x) => {
        return dispatch(resetPassword(x))
    },
    resetPasswordSuccess: (x) => {
        return dispatch(resetPasswordSuccess(x))
    }

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)

