import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams } from "react-router-dom";

import { getUsers, login, verifyEmail, verifyEmailSuccess } from '../../../actions/actions';
import { selectUsersData, selectUserToken, selectLoginFailure, selectVerifyEmailSuccessData } from '../../../selectors/selectors';

import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css'


const VerifyEmail = ({
    users,
    getUsers,
    token,
    login,
    error,
    verifyEmailSuccessData,
    verifyEmailSuccess,
    verifyEmail
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(true);
    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory();
    const pathVal = useParams();
    const { id } = pathVal ? pathVal : { id: '' }
    const authed = localStorage.getItem('isAdminAuthenticated')
    const authedtoken = localStorage.getItem('token')


    const showMsg = () =>{
        if(verifyEmailSuccessData && verifyEmailSuccessData.success){
            window.alert('User verified successfully !!')
        }else{
            window.alert('User verification failed !!')
        }
    }

    useEffect(() => {
        verifyEmail({id:id})
    },[])

    console.log(verifyEmailSuccessData)

    return (
        <div>
            <div>
                <title>MindBank | Verify email</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"></link>
            </div>

            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 p-0 verified-email-login-bg">
                            <div className="login-bg">
                                {/*<!-- <img alt="Logo" className="img-responsive signin-logo" src="assets/images/logo.png" width="198" />
                        <p><span>PROMAKERS</span> push their own limits in the game and in life. Get Inspired to elevate
                            your training with the best programs & products in sports and fitness – Become a
                            <span>POWERHANDZ PROMAKER</span>.</p> -->*/}
                            </div>
                        </div>
                        <div className="col-6 p-0 verified-col-6">
                            {(verifyEmailSuccessData)?
                            showMsg() : null
                            }
                            <div data-testid="verify-email" class="signin-container verified-email-container">
                                <form class="login-container email-container" action="dashboard.html">
                                    <img alt="Logo" class="img-responsive signin-logo signin-logo-space" src="/images/logo.png" width="198" />
                                    {(verifyEmailSuccessData && verifyEmailSuccessData.success)?
                                    <>
                                    <img alt="Logo" class="img-responsive forgotemailsent-logo" src="/images/verified-email.svg" />
                                    <h1 id="head-bootomsapce" className="text-uppercase">verified!</h1>
                                    <p class="sub-head-center">You have successfully verified the account.</p>
                                    </>
                                    :(verifyEmailSuccessData)?
                                    <p class="sub-head-center">User verification failed.</p>
                                    :
                                    <p class="sub-head-center">Verifying your account.</p>
                                    }

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    // users:selectUsersData(),
    token: selectUserToken(),
    error: selectLoginFailure(),
    verifyEmailSuccessData: selectVerifyEmailSuccessData()
});


const mapDispatchToProps = dispatch => ({
    // getUsers: () => {
    //   return dispatch(getUsers())
    // },
    login: (x) => {
        return dispatch(login(x))
    },
    verifyEmail: (x) =>{
        return dispatch(verifyEmail(x))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyEmail)

