import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'



import { fromJS } from 'immutable';



import {
    getUsers,
    getUsersSuccess,
    getUsersFailure,
    login,
    login_success,
    login_faliure,
    getSharedRecording,
    getSharedRecordingSuccess,
    getSharedRecordingFailure,
    getCategoriesList,
    getCategoriesListSuccess,
    getCategoriesListFailure,
    getQuestionsList,
    getQuestionsListSuccess,
    getQuestionsListFailure,
    uploadCategoryJson,
    uploadCategoryJsonSuccess,
    uploadCategoryJsonFailure,
    getUserssList,
    getUsersListSuccess,
    getUsersListFailure,
    changeUserStatus,
    changeUserStatusSuccess,
    changeUserStatusFaliure,
    getStaticContent,
    getStaticContentSuccess,
    getStaticContentFaliure,
    updateStaticContent,
    updateStaticContentSuccess,
    updateStaticContentFailure,
    uploadQuestionJson,
    uploadQuestionJsonSuccess,
    uploadQuestionJsonFailure,
    updateCategoryImage,
    updateCategoryImageSuccess,
    updateCategoryImageFailure,
    changePassword,
    changePasswordSuccess,
    changePasswordFailure,
    changeSubscriptionStatus,
    changeSubscriptionStatusSuccess,
    changeSubscriptionStatusFaliure,
    getUserReport,
    getUserReportSuccess,
    getUserReportFaliure,
    getRecordingReport,
    getRecordingReportSuccess,
    getRecordingReportFaliure,
    sendResetPasswordEmail,
    sendResetPasswordEmailSuccess,
    sendResetPasswordEmailFaliure,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFaliure,
    deleteCategory,
    deleteCategorySuccess,
    deleteCategoryFaliure,
    deleteQuestion,
    deleteQuestionSuccess,
    deleteQuestionFaliure,
    verifyEmail,
    verifyEmailSuccess,
    verifyEmailFaliure,
    getSubscribedUser,
    getSubscribedUserSuccess,
    getSubscribedUserFaliure
} from '../actions/actions';

import {
    USERS_GET,
    USERS_SUCCESS,
    USERS_FAILURE,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FALIURE,
    GET_SHARED_RECORDING,
    GET_SHARED_RECORDING_SUCCESS,
    GET_SHARED_RECORDING_FALIURE,
    GET_CATEGORIES_LIST,
    GET_CATEGORIES_LIST_SUCCESS,
    GET_CATEGORIES_LIST_FALIURE,
    GET_QUESTIONS_LIST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FALIURE,
    UPLOAD_CATEGORY_JSON,
    UPLOAD_CATEGORY_JSON_SUCCESS,
    UPLOAD_CATEGORY_JSON_FALIURE,
    GET_USERS_LIST,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FALIURE,
    CHANGE_USER_STATUS,
    CHANGE_USER_STATUS_SUCCESS,
    CHANGE_USER_STATUS_FALIURE,
    GET_STATIC_CONTENT,
    GET_STATIC_CONTENT_SUCCESS,
    GET_STATIC_CONTENT_FALIURE,
    UPDATE_STATIC_CONTENT,
    UPDATE_STATIC_CONTENT_SUCCESS,
    UPDATE_STATIC_CONTENT_FALIURE,
    UPLOAD_QUESTION_JSON,
    UPLOAD_QUESTION_JSON_SUCCESS,
    UPLOAD_QUESTION_JSON_FALIURE,
    UPDATE_CATEGORY_IMAGE,
    UPDATE_CATEGORY_IMAGE_SUCCESS,
    UPDATE_CATEGORY_IMAGE_FALIURE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FALIURE,
    GET_ALL_SUBSCRIPTIONS,
    GET_ALL_SUBSCRIPTIONS_SUCCESS,
    GET_ALL_SUBSCRIPTIONS_FALIURE,
    CHANGE_SUBSCRIPTION_STATUS,
    CHANGE_SUBSCRIPTION_STATUS_SUCCESS,
    CHANGE_SUBSCRIPTION_STATUS_FALIURE,
    GET_USER_REPORT_SUCCESS,
    GET_RECORDING_REPORT_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_FALIURE,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FALIURE,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FALIURE,
    DELETE_QUESTION,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FALIURE,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FALIURE,
    GET_SUBSCRIBED_USER,
    GET_SUBSCRIBED_USER_SUCCESS,
    GET_SUBSCRIBED_USER_FALIURE
} from '../constants/constants';

const initialState = fromJS({
  users:null,
  allProducts:null,
  categoriesList:['loading'],
  questionsList:['loading'],
  categoriesUploaded:'',
  token:'',
  usersList:{},
  staticContent:'',
});

const rootReducer = (state = initialState , action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.set('token',fromJS(action.payload));
    case LOGIN_FALIURE:
      return state.set('error',fromJS(action.payload));
    case USERS_SUCCESS:
      return state.set('users', fromJS(action.users));
    case GET_SHARED_RECORDING_SUCCESS:
      return state.set('sharedRecordingDetails',fromJS(action.sharedRecordingDetails));
    case GET_CATEGORIES_LIST_SUCCESS:
      return state.set('categoriesList',fromJS(action.categoriesList));
    case GET_QUESTIONS_LIST_SUCCESS:
      return state.set('questionsList',fromJS(action.questionsList));
    case UPLOAD_CATEGORY_JSON_SUCCESS:
      return state.set('categoriesUploaded',fromJS(action.categoriesUploaded));
    case UPLOAD_QUESTION_JSON_SUCCESS:
      return state.set('questionsUploaded',fromJS(action.questionsUploaded));
    case GET_USERS_LIST_SUCCESS:
      return state.set('usersList',fromJS(action.usersList));
    case CHANGE_USER_STATUS_SUCCESS:
      return state.set('userStatusChange',fromJS(action.data));
    case GET_STATIC_CONTENT_SUCCESS:
      return state.set('staticContent',fromJS(action.data));
    case UPDATE_STATIC_CONTENT_SUCCESS:
      return state.set('updateContent',fromJS(action.data));
    case UPDATE_CATEGORY_IMAGE_SUCCESS:
      return state.set('updateCategoryImageData',fromJS(action.data));
    case CHANGE_PASSWORD_SUCCESS:
      return state.set('changePasswordData',fromJS(action.data));
    case GET_ALL_SUBSCRIPTIONS_SUCCESS:
      return state.set('allSubscriptionsData',fromJS(action.data));
    case CHANGE_SUBSCRIPTION_STATUS_SUCCESS:
      return state.set('subscriptionStatus',fromJS(action.data));
    case GET_USER_REPORT_SUCCESS:
      return state.set('userReport',fromJS(action.data));
    case GET_RECORDING_REPORT_SUCCESS:
      return state.set('recordingReport',fromJS(action.data));
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return state.set('emailSent',fromJS(action.data));
    case RESET_PASSWORD_SUCCESS:
      return state.set('resetPasswordData',fromJS(action.data));
    case DELETE_CATEGORY_SUCCESS:
      return state.set('deleteCategorySuccessData',fromJS(action.data));
    case DELETE_QUESTION_SUCCESS:
      return state.set('deleteQuestionSuccessData',fromJS(action.data));
    case VERIFY_EMAIL_SUCCESS:
      return state.set('verifyEmailSuccessData',fromJS(action.data));
    case GET_SUBSCRIBED_USER_SUCCESS:
      return state.set('subscribedUsers',fromJS(action.data));
    default:
      return state
  }
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    rootReducer,
  })

