import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'


import './index.css'


const ProtectRoute = ({children}) => {
  const authed = localStorage.getItem('isAdminAuthenticated')
  return (
    authed 
    ? children 
    : <Redirect to="/admin/login" />
  )
}

export default ProtectRoute
