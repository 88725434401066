export const USERS_GET = 'USERS_GET';
export const USERS_SUCCESS = 'USERS_SUCCESS';

export const USERS_FAILURE = 'USERS_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FALIURE = 'LOGIN_FALIURE';

export const GET_SHARED_RECORDING = 'GET_SHARED_RECORDING';
export const GET_SHARED_RECORDING_SUCCESS = 'GET_SHARED_RECORDING_SUCCESS';
export const GET_SHARED_RECORDING_FALIURE = 'GET_SHARED_RECORDING_FALIURE';

export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
export const GET_CATEGORIES_LIST_FALIURE = 'GET_CATEGORIES_LIST_FALIURE';

export const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST';
export const GET_QUESTIONS_LIST_SUCCESS = 'GET_QUESTIONS_LIST_SUCCESS';
export const GET_QUESTIONS_LIST_FALIURE = 'GET_QUESTIONS_LIST_FALIURE';

export const UPLOAD_CATEGORY_JSON = 'UPLOAD_CATEGORY_JSON';
export const UPLOAD_CATEGORY_JSON_SUCCESS = 'UPLOAD_CATEGORY_JSON_SUCCESS';
export const UPLOAD_CATEGORY_JSON_FALIURE = 'UPLOAD_CATEGORY_JSON_FALIURE';

export const UPDATE_CATEGORY_IMAGE = 'UPDATE_CATEGORY_IMAGE';
export const UPDATE_CATEGORY_IMAGE_SUCCESS = 'UPDATE_CATEGORY_IMAGE_SUCCESS';
export const UPDATE_CATEGORY_IMAGE_FALIURE = 'UPDATE_CATEGORY_IMAGE_FALIURE';

export const UPLOAD_QUESTION_JSON = 'UPLOAD_QUESTION_JSON';
export const UPLOAD_QUESTION_JSON_SUCCESS = 'UPLOAD_QUESTION_JSON_SUCCESS';
export const UPLOAD_QUESTION_JSON_FALIURE = 'UPLOAD_QUESTION_JSON_FALIURE';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FALIURE = 'GET_USERS_LIST_FALIURE';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FALIURE = 'CHANGE_USER_STATUS_FALIURE';

export const GET_STATIC_CONTENT = 'GET_STATIC_CONTENT';
export const GET_STATIC_CONTENT_SUCCESS = 'GET_STATIC_CONTENT_SUCCESS';
export const GET_STATIC_CONTENT_FALIURE = 'GET_STATIC_CONTENT_FALIURE';

export const UPDATE_STATIC_CONTENT = 'UPDATE_STATIC_CONTENT';
export const UPDATE_STATIC_CONTENT_SUCCESS = 'UPDATE_STATIC_CONTENT_SUCCESS';
export const UPDATE_STATIC_CONTENT_FALIURE = 'UPDATE_STATIC_CONTENT_FALIURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FALIURE = 'CHANGE_PASSWORD_FALIURE';

export const GET_ALL_SUBSCRIPTIONS = 'GET_ALL_SUBSCRIPTIONS';
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'GET_ALL_SUBSCRIPTIONS_SUCCESS';
export const GET_ALL_SUBSCRIPTIONS_FALIURE = 'GET_ALL_SUBSCRIPTIONS_FALIURE';

export const CHANGE_SUBSCRIPTION_STATUS = 'CHANGE_SUBSCRIPTION_STATUS';
export const CHANGE_SUBSCRIPTION_STATUS_SUCCESS = 'CHANGE_SUBSCRIPTION_STATUS_SUCCESS';
export const CHANGE_SUBSCRIPTION_STATUS_FALIURE = 'CHANGE_SUBSCRIPTION_STATUS_FALIURE';

export const GET_USER_REPORT = 'GET_USER_REPORT';
export const GET_USER_REPORT_SUCCESS = 'GET_USER_REPORT_SUCCESS';
export const GET_USER_REPORT_FALIURE = 'GET_USER_REPORT_FALIURE';

export const GET_RECORDING_REPORT = 'GET_RECORDING_REPORT';
export const GET_RECORDING_REPORT_SUCCESS = 'GET_RECORDING_REPORT_SUCCESS';
export const GET_RECORDING_REPORT_FALIURE = 'GET_RECORDING_REPORT_FALIURE';

export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_FALIURE = 'SEND_RESET_PASSWORD_EMAIL_FALIURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FALIURE = 'RESET_PASSWORD_FALIURE';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FALIURE = 'DELETE_CATEGORY_FALIURE';

export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FALIURE = 'DELETE_QUESTION_FALIURE';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FALIURE = 'VERIFY_EMAIL_FALIURE';

export const GET_SUBSCRIBED_USER = 'GET_SUBSCRIBED_USER';
export const GET_SUBSCRIBED_USER_SUCCESS = 'GET_SUBSCRIBED_USER_SUCCESS';
export const GET_SUBSCRIBED_USER_FALIURE = 'GET_SUBSCRIBED_USER_FALIURE';