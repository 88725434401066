import { createSelector } from 'reselect';

const rootReducer = (state) => state.rootReducer;


export const selectUsersData = () => createSelector(rootReducer, (state) => (state.get('users'))?state.get('users').toJS():state.get('users'));

export const selectUserToken = () => createSelector(rootReducer, (state) => (state.get('token')));

export const selectSharedRecordingData = () => createSelector(rootReducer, (state) => (state.get('sharedRecordingDetails'))?state.get('sharedRecordingDetails').toJS():state.get('sharedRecordingDetails'));

export const selectCategoriesListData = () => createSelector(rootReducer, (state) => (state.get('categoriesList'))?state.get('categoriesList').toJS():state.get('categoriesList'));

export const selectQuestionsListData = () => createSelector(rootReducer, (state) => (state.get('questionsList'))?state.get('questionsList').toJS():state.get('questionsList'));

export const selectCategoriesUploadedData = () => createSelector(rootReducer, (state) => (state.get('categoriesUploaded'))?state.get('categoriesUploaded').toJS():state.get('categoriesUploaded'));

export const selectQuestionsUploadedData = () => createSelector(rootReducer, (state) => (state.get('questionsUploaded'))?state.get('questionsUploaded').toJS():state.get('questionsUploaded'));

export const selectLoginFailure = () => createSelector(rootReducer, (state) => (state.get('error'))?state.get('error').toJS():state.get('error'));

export const selectUsersList = () => createSelector(rootReducer, (state) => (state.get('usersList'))?state.get('usersList').toJS():state.get('usersList'));

export const selectUserStatusChange = () => createSelector(rootReducer, (state) => (state.get('userStatusChange'))?state.get('userStatusChange').toJS():state.get('userStatusChange'));

export const selectStaticContent = () => createSelector(rootReducer, (state) => (state.get('staticContent'))?state.get('staticContent').toJS():state.get('staticContent'));

export const selectUpdateContent = () => createSelector(rootReducer, (state) => (state.get('updateContent'))?state.get('updateContent').toJS():state.get('updateContent'));

export const selectUpdateCategoryImage = () => createSelector(rootReducer, (state) => (state.get('updateCategoryImageData'))?state.get('updateCategoryImageData').toJS():state.get('updateCategoryImageData'));

export const selectChangePasswordData = () => createSelector(rootReducer, (state) => (state.get('changePasswordData'))?state.get('changePasswordData').toJS():state.get('changePasswordData'));

export const selectAllSubscriptions = () => createSelector(rootReducer, (state) => (state.get('allSubscriptionsData'))?state.get('allSubscriptionsData').toJS():state.get('allSubscriptionsData'));

export const selectChangeSubscriptionStatus = () => createSelector(rootReducer, (state) => (state.get('subscriptionStatus'))?state.get('subscriptionStatus').toJS():state.get('subscriptionStatus'));

export const selectGetUserReportData = () => createSelector(rootReducer, (state) => (state.get('userReport'))?state.get('userReport').toJS():state.get('userReport'));

export const selectGetRecordingReportData = () => createSelector(rootReducer, (state) => (state.get('recordingReport'))?state.get('recordingReport').toJS():state.get('recordingReport'));

export const selectSendEmailData = () => createSelector(rootReducer, (state) => (state.get('emailSent'))?state.get('emailSent').toJS():state.get('emailSent'));

export const selectResetPasswordData = () => createSelector(rootReducer, (state) => (state.get('resetPasswordData'))?state.get('resetPasswordData').toJS():state.get('resetPasswordData'));

export const selectDeleteCategorySuccessData = () => createSelector(rootReducer, (state) => (state.get('deleteCategorySuccessData'))?state.get('deleteCategorySuccessData').toJS():state.get('deleteCategorySuccessData'));

export const selectDeleteQuestionSuccessData = () => createSelector(rootReducer, (state) => (state.get('deleteQuestionSuccessData'))?state.get('deleteQuestionSuccessData').toJS():state.get('deleteQuestionSuccessData'));

export const selectVerifyEmailSuccessData = () => createSelector(rootReducer, (state) => (state.get('verifyEmailSuccessData'))?state.get('verifyEmailSuccessData').toJS():state.get('verifyEmailSuccessData'));

export const selectSubscribedUsersData = () => createSelector(rootReducer, (state) => (state.get('subscribedUsers'))?state.get('subscribedUsers').toJS():state.get('subscribedUsers'));