import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { useHistory } from "react-router-dom";

import { sendResetPasswordEmail, sendResetPasswordEmailSuccess } from '../../../actions/actions';
import { selectSendEmailData } from '../../../selectors/selectors';

// import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css';

import EmailSent from './emailSent';
import Popup from '../../common/Popup';


const ForgotPassword = ({
    users,
    getUsers,
    token,
    login,
    error,
    emailSent,
    sendResetPasswordEmail,
    sendResetPasswordEmailSuccess,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [language, setLanguage] = useState('en')
    const [showPopup, setShowPopup] = useState(false);
    const [popUpType, setPopUpType] = useState('alert')
    const [popUpBody, setPopUpBody] = useState('')
    const [popUpHeading, setPopUpHeading] = useState('')
    const [confirmed, setConfirmed] = useState(false)

    const history = useHistory();

    const authed = localStorage.getItem('isAdminAuthenticated')
    const authedtoken = localStorage.getItem('token')

    const setEmailFunc = (e) => {
        setEmail(e.target.value)
    }

    const submitFunc = () => {
        if(email){
            sendResetPasswordEmail({"email":email})
        }else{
            setShowPopup(true)
            setPopUpHeading('Forgot Password')
            setPopUpBody('Please Enter Email ID')
            setPopUpType('alert')
        }
    }

    const showErrMsgFunc = () =>{
        setShowPopup(true)
        setPopUpHeading('Forgot Password')
        setPopUpBody('Please Enter Valid Email ID')
        setPopUpType('alert')
        // history.push('/admin/forgot-password')
    }


    useEffect(() => {
        if (authed) {
            history.push('/admin/dashboard/user-management')
        }
        sendResetPasswordEmailSuccess()
    },[])

    useEffect(() => {
        if (emailSent && !emailSent.success) {
            showErrMsgFunc()
        }
    },[emailSent])

    return (
        <div>
            <div>
                <title>MindBank | Reset password</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" />
            </div>
            {(showPopup)?
              <Popup
                setShowPopup={setShowPopup}
                popUpBody={popUpBody}
                popUpHeading={popUpHeading}
                popUpType={popUpType}
                setConfirmed={setConfirmed}
              />:null
            }
            <div>
            {(emailSent && emailSent.success) ?
                <EmailSent /> :
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 p-0">
                            <div className="login-bg">
                                {/*<!-- <img alt="Logo" className="img-responsive signin-logo" src="assets/images/logo.png" width="198" />
                        <p><span>PROMAKERS</span> push their own limits in the game and in life. Get Inspired to elevate
                            your training with the best programs & products in sports and fitness – Become a
                            <span>POWERHANDZ PROMAKER</span>.</p> -->*/}
                            </div>
                        </div>
                    <div className="col-6 p-0">
                        {(error && showErrMsg) ?
                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                <span className="icon icon-info"></span> {error.message}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => { setShowErrMsg(false) }}></button>
                            </div> : null

                        }
                        <div class="signin-container">

                    <div class="login-container">
                        <img alt="Logo" className="img-responsive signin-logo" src="/images/logo.png" width="198" />
                        <h1  id="head-topmsapce">Forgot Password?</h1>
                        <p class="sub-head-center">To reset your password, please enter your registered <br /> email and we will send you a link.</p>
                        

                        
                        <div class="form-group form-group-space">
                            <label for="email">Email Address</label>
                            <input data-testid="email-input" class="form-control" id="email" type="text" placeholder="Enter Email ID" onChange={setEmailFunc} />
                        </div>
                        
                        <div class="form-action">
                            <button data-testid="submit-button" class="btn primary primary-uppercase btn-block" type="submit" onClick={submitFunc}>Submit</button>
                        </div>
                        <a onClick ={()=>{history.push('/admin/login')}} title="Sign In" class="forgot-link forgot-link-center">Back to SIGN IN</a>


                    </div>

                </div>
                    </div>
                </div>
            </div>
        }
        </div>
    </div>
    )
}

const mapStateToProps = createStructuredSelector({
    // users:selectUsersData(),
    emailSent: selectSendEmailData()
});


const mapDispatchToProps = dispatch => ({
    // getUsers: () => {
    //   return dispatch(getUsers())
    // },
    sendResetPasswordEmail: (x) => {
        return dispatch(sendResetPasswordEmail(x))
    },
    sendResetPasswordEmailSuccess: (x) => {
        return dispatch(sendResetPasswordEmailSuccess(x))
    }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword)

